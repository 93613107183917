import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import { GET_TAX_DOCUMENTS } from './queries'

export interface TaxDocumentItem {
  id: string
  name: string
  fileName: string
  year: number
}

export interface TaxDocumentsResponse {
  items: TaxDocumentItem[]
  count: number
}

export const useTaxDocuments = () => {
  return useQuery<TaxDocumentsResponse, StrikeFetchError<ErrorDetails>>(
    [GET_TAX_DOCUMENTS],
    () =>
      fetch<TaxDocumentsResponse>('/api/documents/tax-documents', {
        method: 'GET',
      }),
    { staleTime: Infinity, cacheTime: Infinity },
  )
}

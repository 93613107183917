import { StrikeWordmarkIcon } from '@strike-apps/shared/icons'
import { Box, Flex, Link } from '@strike-apps/shared/ui'
import NextLink from 'next/link'

export const Footer = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={6}
      borderTopColor="borderPrimary"
      borderTopWidth="1px"
    >
      <FooterLinks
        links={[
          {
            name: 'API terms',
            url: 'https://developer.strike.me/terms/',
          },
          {
            name: 'Business terms',
            url: 'https://strike.me/business-terms/',
          },
          {
            name: 'Privacy notice',
            url: 'https://developer.strike.me/legal/privacy/',
          },
        ]}
      />
      <Box color="facePrimary">
        <StrikeWordmarkIcon width={38} height={8} />
      </Box>
    </Flex>
  )
}

type LinkData = {
  name: string
  url: string
}

function FooterLinks({ links }: { links: LinkData[] }) {
  return (
    <Flex gap={4}>
      {links.map((link) => {
        return (
          <Link
            textStyle="caption"
            color="faceTertiary"
            textDecoration="none"
            fontWeight="normal"
            as={NextLink}
            href={link.url}
            key={link.name}
            sx={{
              '&:hover': { textDecoration: 'none' },
            }}
          >
            {link.name}
          </Link>
        )
      })}
    </Flex>
  )
}

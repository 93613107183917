import { useUserInfo } from './useUserInfo'

export const useIsAccountReadOnly = () => {
  const { data: userInfo } = useUserInfo()
  return (
    userInfo?.restrictionReason === 'GENERAL_RESTRICTION' ||
    userInfo?.restrictionReason === 'KYC_REVIEW' ||
    userInfo?.restrictionReason === 'KYC_REJECTED' ||
    userInfo?.restrictionReason === 'REQUIRED_TASK'
  )
}

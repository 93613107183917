import { useField, type FieldProps } from 'informed'
import { Input, type InputProps } from '../Input'

export interface InformedDateTimeLocalFieldProps extends InputProps {
  field: string
  informed?: Omit<FieldProps<unknown>, 'name'>
}

export function InformedDateTimeLocalField({
  field,
  informed,
  ...props
}: InformedDateTimeLocalFieldProps) {
  const { fieldApi } = useField({ name: field, ...informed })
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      fieldApi.setValue(undefined)
      return
    }

    const date = new Date(event.target.value)
    const unixTimestamp = date.getTime()
    fieldApi.setValue(unixTimestamp)
  }

  return <Input {...props} type="datetime-local" onChange={handleChange} />
}

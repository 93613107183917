import { isStrikeFetchError } from '@strike-apps/strike/fetch'
import { isErrorWithCode } from '@strike-apps/shared/utils'

export const getRoadblockRedirectDestinationFromError = (error: unknown) => {
  const redirectBasePath = '/restricted'

  if (!isStrikeFetchError(error) || !isErrorWithCode(error)) {
    return null
  }

  if (error.response.status === 403) {
    return (
      {
        BLOCKED_REGION: `${redirectBasePath}/blocked-region`,
        DISABLED: `${redirectBasePath}/blocked`,
        CLOSED: `${redirectBasePath}/closed`,
        CLOSED_FINAL: `${redirectBasePath}/closed-final`,
      }[error.data.code] ?? null
    )
  }

  if (error.response.status === 429) {
    const retryAfter = error.response.headers.get('retry-after')
    return retryAfter
      ? `${redirectBasePath}/paused?retryAfter=${retryAfter}`
      : `${redirectBasePath}/paused`
  }

  if (error.response.status === 503 && error.data.code === 'MAINTENANCE_MODE') {
    return `${redirectBasePath}/maintenance-mode`
  }

  return null
}

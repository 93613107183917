import type { PaymentMethodTypesV2Response } from '@ln-zap/consumer-api'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { StrikeFetchError } from '@strike-apps/strike/fetch'
import { fetch } from '@strike-apps/strike/fetch'
import { useQuery } from '@tanstack/react-query'
import { PAYMENT_METHOD_TYPES_QUERY } from './queries'

export const usePaymentMethodTypes = () => {
  return useQuery<PaymentMethodTypesV2Response, StrikeFetchError<ErrorDetails>>(
    [PAYMENT_METHOD_TYPES_QUERY],
    () => fetch<PaymentMethodTypesV2Response>('/api/payment-methods/types', { method: 'GET' }),
    {
      staleTime: 5000,
    },
  )
}

import type { ReactElement } from 'react'

import { Sidebar, SidebarMenuItem, type SidebarProps } from '@strike-apps/commerce-dashboard/ui'
import { BankIcon, HomeIcon, TransferIcon } from '@strike-apps/shared/icons'

import { type UserMode } from '../context/UserSettingsContext'
export interface SidebarMenuProps extends SidebarProps {
  activeRoute: string
  mode: UserMode
  onClick?: () => void
  renderLink?: (children: ReactElement, href: string) => ReactElement
}

export const SidebarMenu = ({ activeRoute, renderLink, mode }: SidebarMenuProps) => {
  const isCurrentRoute = (route: string) => activeRoute === route

  return (
    <Sidebar>
      <SidebarMenuItem
        href="/"
        isActive={isCurrentRoute('/')}
        renderLink={renderLink}
        IconComponent={HomeIcon}
        label="Home"
      />
      <SidebarMenuItem
        href="/transactions"
        isActive={isCurrentRoute('/transactions')}
        renderLink={renderLink}
        IconComponent={TransferIcon}
        label="Activity"
      />

      {mode === 'merchant' && (
        <SidebarMenuItem
          href="/accounts"
          isActive={isCurrentRoute('/accounts')}
          renderLink={renderLink}
          IconComponent={BankIcon}
          label="Banking"
        />
      )}
    </Sidebar>
  )
}

import { type ReactNode, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { Text } from '../typography/Text'
import { Flex } from '../layout/Flex'
import { Button } from '../forms/Button'
import { noop } from 'lodash-es'

interface ConfirmDialogProps {
  isOpen: boolean
  onClose: () => void
  title: ReactNode
  body: ReactNode
  confirmText: string
  onConfirm: () => void
  isConfirmDesctructive?: boolean
  cancelText?: string
  onCancel?: () => void
}

export const ConfirmDialog = ({
  isOpen,
  onClose,
  title,
  body,
  confirmText,
  onConfirm,
  isConfirmDesctructive = true,
  cancelText = 'Cancel',
  onCancel = noop,
}: ConfirmDialogProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }
  const handleCancel = () => {
    onCancel()
    onClose()
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent bg="layerPrimary" alignSelf="center" maxW="327px" textAlign="center">
          <AlertDialogHeader style={{ paddingBottom: 8 }}>
            <Text variant="title4">{title}</Text>
          </AlertDialogHeader>
          <AlertDialogBody style={{ paddingTop: 0 }}>
            <Text variant="body3">{body}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Flex gap={2}>
              <Button
                flex={1}
                colorScheme="secondary"
                ref={cancelRef}
                onClick={handleCancel}
                size="md"
              >
                {cancelText}
              </Button>
              <Button
                flex={1}
                variant={isConfirmDesctructive ? 'outline' : 'solid'}
                colorScheme={isConfirmDesctructive ? 'negative' : 'primary'}
                onClick={handleConfirm}
                size="md"
              >
                {confirmText}
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useSupportLink } from '@strike-apps/commerce-dashboard/util-support'
import { CircleCheckIcon, InfoIcon } from '@strike-apps/shared/icons'
import type { DrawerProps, SwitchProps } from '@strike-apps/shared/ui'
import {
  Button,
  InformedForm,
  InformedTextField,
  ModalCloseButton,
  Switch,
  useDisclosure,
  useToast,
} from '@strike-apps/shared/ui'
import { Multistep, useMultistepApi, type FormApi, type FormState } from 'informed'
import { useDisable2FA, useEnable2FA, useSetup2FA } from '../hooks'
import { Enable2FA, Enable2FAError } from './Enable2FA'

const INVALID_CODE_MESSAGE = 'This code is invalid, please try again.'

function ConfirmStep({ isBusy, title }: { isBusy: boolean; title: string }) {
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <InformedTextField
          informed={{
            field: 'code',
            required: true,
            formatter: '######',
          }}
          type="text"
          placeholder="000000"
          label="Authentication code"
        />
      </ModalBody>

      <ModalFooter>
        <Button isLoading={isBusy} size="md" type="submit">
          Confirm
        </Button>
      </ModalFooter>
    </>
  )
}
function SetupStep() {
  const { next } = useMultistepApi()
  const { data, isLoading, error, refetch } = useSetup2FA()

  return (
    <>
      <ModalHeader>Set up Authenticator</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {error && <Enable2FAError />}
        {data && !isLoading && <Enable2FA secret={data.secretKey} uri={data.qrCodeUri} />}
        {isLoading && <Enable2FA isLoading={isLoading} />}
      </ModalBody>

      <ModalFooter>
        {!error && (
          <Button isLoading={isLoading} size="md" onClick={next}>
            Continue
          </Button>
        )}
        {error && <Button onClick={() => refetch()}>Try again</Button>}
      </ModalFooter>
    </>
  )
}

export type Enable2FADialogProps = Omit<DrawerProps, 'title' | 'children'>

type FormValues = {
  confirm: {
    code: string
  }
}

function use2FAGenericErrorToast() {
  const { showToast } = useToast('GENERIC_2FA_ERROR_TOAST')
  const { openSupportLink, supportEmailAddress } = useSupportLink()
  const showErrorToast = () => {
    showToast({
      toastType: 'error',
      icon: InfoIcon,
      title: `2FA error occurred`,
      message: `An error occurred while processing your 2FA request. Please try again or contact ${supportEmailAddress} if the problem persists`,
      linkLabel: 'Contact Support',
      linkAction: openSupportLink,
    })
  }
  return showErrorToast
}

const Enable2FADialog = (props: Enable2FADialogProps) => {
  const { showToast } = useToast('2FA_ENABLE_TOAST')
  const showErrorToast = use2FAGenericErrorToast()
  const { mutateAsync: verifyCode, isLoading } = useEnable2FA()

  async function onSubmit(values: FormValues, formApi: FormApi) {
    try {
      const success = await verifyCode(values.confirm.code)
      if (success.enabled) {
        showToast({
          toastType: 'success',
          icon: CircleCheckIcon,
          title: `Two factor verification enabled`,
          message: 'You can now use your authenticator app to verify your log in.',
        })
        props.onClose()
      } else {
        formApi.setError('confirm.code', INVALID_CODE_MESSAGE)
      }
    } catch (error) {
      showErrorToast()
    }
  }
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <InformedForm
          autoComplete="off"
          onSubmit={({ values }, formApi) => onSubmit(values as unknown as FormValues, formApi)}
        >
          <Multistep>
            <Multistep.Step step="setup">
              <SetupStep />
            </Multistep.Step>
            <Multistep.Step step="confirm">
              <ConfirmStep title="Enable 2FA" isBusy={isLoading} />
            </Multistep.Step>
          </Multistep>
        </InformedForm>
      </ModalContent>
    </Modal>
  )
}
const Disable2FADialog = (props: Enable2FADialogProps) => {
  const showErrorToast = use2FAGenericErrorToast()
  const { showToast } = useToast('2FA_ENABLE_TOAST')
  const { mutateAsync: verifyCode, isLoading } = useDisable2FA()

  async function onSubmit({ values }: FormState, formApi: FormApi) {
    try {
      const success = await verifyCode(values.code as string)
      if (success.enabled === false) {
        showToast({
          toastType: 'success',
          icon: CircleCheckIcon,
          title: `2FA disabled`,
          message: 'An authentication code is no longer required to access your account.',
        })
        props.onClose()
      } else {
        formApi.setError('code', INVALID_CODE_MESSAGE)
      }
    } catch (error) {
      showErrorToast()
    }
  }
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <InformedForm autoComplete="off" onSubmit={onSubmit}>
          <ConfirmStep title="Disable 2FA" isBusy={isLoading} />
        </InformedForm>
      </ModalContent>
    </Modal>
  )
}

type Manage2FAButtonProps = SwitchProps & {
  is2FAEnabled: boolean | null
}

export const Manage2FAButton = ({ is2FAEnabled, ...rest }: Manage2FAButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Switch
        size="md"
        {...rest}
        isDisabled={is2FAEnabled === null}
        isChecked={Boolean(is2FAEnabled)}
        onChange={onOpen}
        aria-label={is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
      />
      {is2FAEnabled ? (
        <Disable2FADialog isOpen={isOpen} onClose={onClose} />
      ) : (
        <Enable2FADialog isOpen={isOpen} onClose={onClose} />
      )}
    </>
  )
}

import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import { GET_ACCOUNT_STATEMENTS } from './queries'

export interface AccountStatementItem {
  type?: string
  year?: number
  month?: number | null
}

export interface AccountStatementsResponse {
  items: AccountStatementItem[]
  count: number
}

export const useAccountStatements = () => {
  return useQuery<AccountStatementsResponse, StrikeFetchError<ErrorDetails>>(
    [GET_ACCOUNT_STATEMENTS],
    () =>
      fetch<AccountStatementsResponse>('/api/documents/account-statements', {
        method: 'GET',
      }),
    { staleTime: Infinity, cacheTime: Infinity },
  )
}

import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import {
  useSaveUserSettings,
  useUserSettings,
} from '@strike-apps/commerce-dashboard/feature-settings'
import { useIsUS, useTaxDocuments } from '@strike-apps/commerce-dashboard/hooks'
import { Alert } from '@strike-apps/commerce-dashboard/ui'
import { DocumentIcon, TransferIcon } from '@strike-apps/shared/icons'
import { useRouter } from 'next/router'

type PreMainContentType =
  | 'permissions-alert'
  | 'sandbox'
  | 'ach-promo'
  | '2024-tax-documents'
  | null

function usePreMainContent(): PreMainContentType {
  const isUS = useIsUS()
  const { isSandboxEnvironment } = useEnvSettings()
  const { data: userSettings } = useUserSettings()
  const { is2024TaxDocumentsBannerDismissed } = userSettings ?? {}
  const { data: taxDocuments } = useTaxDocuments()
  const has2024TaxDocuments = taxDocuments?.items.some(({ year }) => year === 2024)

  if (isUS && has2024TaxDocuments && !is2024TaxDocumentsBannerDismissed) {
    return '2024-tax-documents'
  }

  if (isSandboxEnvironment) {
    return 'sandbox'
  }

  return null
}

export const PreMainContent = () => {
  const router = useRouter()
  const { mutateAsync: saveUserSettings } = useSaveUserSettings()
  const type = usePreMainContent()

  switch (type) {
    case '2024-tax-documents':
      return router.pathname !== '/documents' ? (
        <Alert
          title="Tax document available"
          description="Your 2024 tax document is now available to view and download."
          actionText="View"
          icon={DocumentIcon}
          onAction={() => router.push('/documents')}
          onDismiss={() => saveUserSettings({ is2024TaxDocumentsBannerDismissed: true })}
        />
      ) : null

    case 'ach-promo':
      return router.pathname !== '/accounts' ? (
        <Alert
          title="ACH bank deposits are here"
          description="Link a bank account within your Strike Dashboard and buy bitcoin instantly."
          actionText="Link a bank account"
          icon={TransferIcon}
          onAction={() => router.push('/accounts')}
          onDismiss={() => saveUserSettings({ isAchBannerDismissed: true })}
        />
      ) : null

    case 'sandbox':
      return <Alert title="Sandbox Environment" />
    default:
      return null
  }
}

export const throwError = (message: string): never => {
  throw new Error(message)
}

interface ErrorWithCode extends Error {
  data: {
    code: string
  }
}

export const isErrorWithCode = (error: unknown): error is ErrorWithCode => {
  return (
    error instanceof Error &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'code' in error.data &&
    typeof error.data['code'] === 'string'
  )
}

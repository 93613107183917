import type { Session } from 'next-auth'
import type { LDContext } from '@launchdarkly/node-server-sdk'

export const parseUserContext = (session?: Session): LDContext => {
  if (!session || !session.user) {
    return {
      kind: 'user',
      anonymous: true,
    } as LDContext
  }

  return {
    kind: 'user',
    key: session.user.id,
    isCompany: session.user.business,
  } as LDContext
}

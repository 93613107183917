/* eslint-disable */

export interface AccountCurrency {
  /**
   * Currency code
   * @example "USD"
   */
  currency: Currency
  /**
   * Signals the default currency for the account
   * @example true
   */
  isDefaultCurrency: boolean
  /**
   * Signals if the currency is the available for the account
   * @example true
   */
  isAvailable: boolean
  /**
   * Signals if the account can issue invoices in this currency
   * @example true
   */
  isInvoiceable: boolean
}
export interface AccountProfile {
  /**
   * Account ID
   * @format uuid
   * @example "e8717a40-0506-485c-b10c-72d2a955d873"
   */
  id: string
  /**
   * Account's handle
   * @example "satoshi"
   */
  handle: string
  /**
   * Account's avatar URL
   * @example "https://strike-avatars.storage.googleapis.com/faa5b70e-0ab7-4d60-87bf-407449724313.PNG"
   */
  avatarUrl?: string
  /**
   * Account's description
   * @example "Hi! My name is Satoshi Nakamoto."
   */
  description?: string
  /**
   * Account can receive funds
   * @example true
   */
  canReceive: boolean
  /** Account's supported currencies */
  currencies: AccountCurrency[]
}
export enum ApiVersion {
  V1 = 'v1',
}
export interface Balance {
  /**
   * The currency of the balance
   * @example "USD"
   */
  currency: Currency
  /**
   * The balance that is currently available for trading (currency conversion).
   * Includes settled and any non spent pending amount
   * */
  current: string
  /**
   * The balance of all deposits, in all currencies, that are pending settlement denominated in this currency. This number impacts the available balance and can
   * cause the available balance to be lower than the current balance
   */
  pending: string
  /**
   * The balance that is reserved to be spent. E.g. for pending withdrawals, target orders, etc.
   */
  reserved: string
  /**
   * @deprecated Use reserved instead
   */
  outgoing: string
  /**
   * The balance available for use
   * @example "100.00"
   */
  available: string
  /**
   * @deprecated Total will be removed in the future. Sum the balances of interest instead on the client side
   */
  total: string
}

/** Represents a conversion rate between fiat or crypto Currency. */
export interface ConversionAmount {
  /**
   * Currency amount in decimal format
   * @example "46707.18"
   */
  amount: string
  /**
   * Source currency code
   * @example "BTC"
   */
  sourceCurrency: Currency
  /**
   * Target currency code
   * @example "USD"
   */
  targetCurrency: Currency
}
export interface CreateInvoiceReq {
  /**
   * Invoice correlation id. Must be a unique value. Can be used to correlate the invoice with an external entity
   * @maxLength 40
   * @example "224bff37-021f-43e5-9b9c-390e3d834720"
   */
  correlationId?: string
  /**
   * Invoice description
   * @maxLength 200
   * @example "Invoice for order 123"
   */
  description?: string
  /**
   * Invoice amount. Only Currency which are invoiceable for the receiver's account can be used. Invoiceable Currency can be found using get account
   * profile endpoint.
   */
  amount: CurrencyAmount
}
export interface CreatePaymentIntentRequest {
  externalId?: string
}
export interface RequestQuoteReq {
  descriptionHash?: string
}
export interface CreateLightningPaymentQuoteReq {
  /** LN invoice to pay. */
  lnInvoice: string
  /**
   * Currency to send from. Defaults to the user's default currency.
   * @example "USD"
   */
  sourceCurrency?: Currency
  /**
   * Amount to send if using zero amount invoice. Must be omitted otherwise.
   */
  amount?: AmountWithFee
}
export interface GetLnPaymentFeeReq {
  /**
   * LN invoice to estimate.
   * @example "lntb10u1p0pneq6pp5ejfhy9tlzm570hvaee26wcda4k2neg94d6rvteqldhzh96z0q22qdqdg9jxgg8sn7xcqcqzpgxqr23ssp5hm6zsaxt5aguxrdrfnr5n56g5alrkllrqs84q4j38faganvaaugq9qy9qsq0tq0mmxwvy8achkgqjd4mky0awt8w50uhqxu0grpqnwf8s860scr5cvazlrsje86hqe8a7phgepyvhep4amyn68d65z0eqme2he5qmgpfzjfxf"
   */
  lnInvoice: string

  /**
   * Amount to send if using zero amount invoice. Must be omitted otherwise.
   */
  amount: CurrencyAmount
}
export interface LnPaymentFee {
  /**
   * The lightning invoice amount
   */
  amount: CurrencyAmount

  /**
   * The fee required by LN network
   */
  lightningNetworkFee: CurrencyAmount

  /**
   * The total amount the sender will be debited
   */
  total: CurrencyAmount
}

export enum FeePolicy {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
}

export interface AmountWithFee extends CurrencyAmount {
  feePolicy?: FeePolicy
}

export interface GetOnchainTiersReq {
  btcAddress: string
  amount: CurrencyAmount
}

export interface CreateOnchainPaymentQuoteReq {
  btcAddress: string
  sourceCurrency?: Currency
  description?: string
  amount: AmountWithFee
  onchainTierId: string
}
export interface OnchainFeeTier {
  id: string
  estimatedDeliveryDurationInMin: number
  estimatedFee: CurrencyAmount
  minimumAmount?: CurrencyAmount
}
export interface CreateSubscription {
  /**
   * Webhook HTTPS endpoint url.
   * @example "https://kramerica_industries.com/webhook"
   */
  webhookUrl: string
  /**
   * Version that will be used when formatting the webhook payload.
   * @example "v1"
   */
  webhookVersion: ApiVersion
  /**
   * Webhook secret that will be used for signing the request.
   * @minLength 10
   * @maxLength 50
   * @example "ScKc2Phr37RzfESKGYPh6CnZqNxc9t"
   */
  secret: string
  /**
   * Flag for enabling/disabling the subscription. If subscription is disabled the webhook
   * won't be triggered for the respective event types.
   */
  enabled: boolean
  /**
   * List of event types for the subscription. Each time that some event type from the list occurs,
   * the webhook will be triggered.
   * @minItems 1
   * @example ["invoice.created","invoice.updated"]
   */
  eventTypes: string[]
}
export enum Currency {
  BTC = 'BTC',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  USDT = 'USDT',
} /** Represents an amount denominated in fiat or crypto Currency. */
export interface CurrencyAmount {
  /**
   * Currency code
   * @example "USD"
   */
  currency: Currency
  /**
   * Currency amount in decimal format
   * @example "10.99"
   */
  amount: string
}
export interface Payment {
  paymentId: string
  state: PaymentQuoteExecutionState
  /**
   * @deprecated Use `state` instead
   */
  result: PaymentQuoteExecutionResult
  completed?: string
  /**
   * @deprecated Use `completed` instead
   */
  delivered?: string
  conversionRate?: ConversionAmount
  amount: CurrencyAmount
  lightningNetworkFee?: CurrencyAmount
  totalFee?: CurrencyAmount
  totalAmount: CurrencyAmount
  reward?: CurrencyAmount
}
export interface InitiateWithdrawal {
  /**
   * Id of the payment method to which the withdrawal will be executed
   * @format uuid
   */
  paymentMethodId: string
  /** Amount to be withdrawn */
  amount: CurrencyAmount
}

export interface InitiateWithdrawalResult {
  /**
   * Withdrawal id
   * @format uuid
   */
  withdrawalId: string
  /** New account balance */
  newBalance: CurrencyAmount
  /** Total amount of all pending withdrawals */
  pendingWithdrawals: CurrencyAmount
}
export interface Invoice {
  /** @format uuid */
  invoiceId: string
  /** Represents an amount denominated in fiat or crypto Currency. */
  amount: CurrencyAmount
  state: InvoiceState
  /** @format date-time */
  created: string
  /**
   * Invoice correlation id
   * @example "224bff37-021f-43e5-9b9c-390e3d834720"
   */
  correlationId?: string
  /**
   * Invoice description
   * @example "Pizza and beer"
   */
  description?: string
  /**
   * Id of the invoice issuer
   * @format uuid
   * @example "7e7671fb-8265-4a7e-91df-23a3b34457df"
   */
  issuerId: string
  /**
   * Id of the invoice receiver
   * @format uuid
   * @example "028d9fe1-069f-4bc4-a1fb-bd591bf62f5b"
   */
  receiverId: string
  /**
   * Id of the invoice payer. Present only when invoice was created for the dedicated payer
   * @format uuid
   * @example "14011616-a18f-47a0-bfef-0687626bec35"
   */
  payerId?: string
}
export interface InvoicePageResult {
  /** The page items. */
  items: Invoice[]
  /**
   * Total number of records.
   * @format int64
   */
  count: number
}
export interface InvoiceQuote {
  /** @format uuid */
  quoteId: string
  /**
   * Quote description
   * @example "Pizza and beer"
   */
  description?: string
  /**
   * Bolt 11 encoded lightning invoice
   * @example "lnbcrt132850n1ps33deupp5f4v3mnfl5m9zwzls8v57m5e3986dg5p2mdnaawc09xwcvv2twfcsdqs2d68y6ttv5s9g6tscqzpgxqzpsfppqk3zea0rdeyn6j7srdywne0ck0m9s7h8ssp5axv0evy3wtkq5huzxxwm4xmrj5zwstxagncv6y53tvztr4r32y0q9qyyssqdsec9g3622ac036yjrkt8m8rju7d3yzqadyect3vh9jm3uhkymkszwa9mp70rzds5tc4wv5l5d4vct0vhu9xeuq4xhnts83z7dw2ddgpv324qk"
   */
  lnInvoice: string
  /**
   * On-chain address that can be used to pay the invoice. Will be present only if you are eligible for on-chain payments
   * @example "3sfdel53xhl59fn0d4nvdry5ut4zygy0"
   */
  onchainAddress?: string
  /**
   * Time at which quote is expiring
   * @format date-time
   */
  expiration: string
  /**
   * Time period in seconds for which quote is valid
   * @format int64
   * @example 60
   */
  expirationInSec: number
  /**
   * Quote amount in currency that will be credited. This currency cannot be BTC
   * @example {"currency":"USD","amount":"10.99"}
   */
  targetAmount: CurrencyAmount
  /**
   * Quote amount in currency that will be debited. At quote creation time this will always be BTC, but that can change to other Currency later in case
   * the transaction is upgraded to direct transfer (happens if the payer is also Strike user)
   * @example {"currency":"BTC","amount":"0.00023563"}
   */
  sourceAmount: CurrencyAmount
  /** Applied source -> target currency conversion rate */
  conversionRate: ConversionAmount
}
export enum InvoiceState {
  UNPAID = 'UNPAID',
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  REVERSED = 'REVERSED',
}
export interface InvoicingConversionAmount {
  /**
   * Currency amount in decimal format
   * @example "46707.18"
   */
  amount: string
  /**
   * Source currency code
   * @example "BTC"
   */
  sourceCurrency: Currency
  /**
   * Target currency code
   * @example "USD"
   */
  targetCurrency: Currency
}
export interface InvoicingCurrencyAmount {
  amount: string
  currency: Currency
}
export interface PaymentIntent {
  /** @format uuid */
  paymentIntentId: string
  token: string
  /** @format date-time */
  created: string
  state: PaymentIntentState
  paymentProcessor?: PaymentProcessor
  amount?: InvoicingCurrencyAmount
  description?: string
  paymentProcessorData?: any
  externalId?: string
}
export interface PaymentIntentPageResult {
  /** The page items. */
  items: PaymentIntent[]
  /**
   * Total number of records.
   * @format int64
   */
  count: number
}
export interface PaymentIntentQuote {
  /** @format uuid */
  quoteId: string
  /**
   * Quote description
   * @example "Pizza and beer"
   */
  description?: string
  /**
   * Bolt 11 encoded lightning invoice
   * @example "lnbcrt132850n1ps33deupp5f4v3mnfl5m9zwzls8v57m5e3986dg5p2mdnaawc09xwcvv2twfcsdqs2d68y6ttv5s9g6tscqzpgxqzpsfppqk3zea0rdeyn6j7srdywne0ck0m9s7h8ssp5axv0evy3wtkq5huzxxwm4xmrj5zwstxagncv6y53tvztr4r32y0q9qyyssqdsec9g3622ac036yjrkt8m8rju7d3yzqadyect3vh9jm3uhkymkszwa9mp70rzds5tc4wv5l5d4vct0vhu9xeuq4xhnts83z7dw2ddgpv324qk"
   */
  lnInvoice: string
  /**
   * On-chain address that can be used to pay the invoice. Will be present only if you are eligible for on-chain payments
   * @example "3sfdel53xhl59fn0d4nvdry5ut4zygy0"
   */
  onchainAddress?: string
  /**
   * Time at which quote is expiring
   * @format date-time
   */
  expiration: string
  /**
   * Time period in seconds for which quote is valid
   * @format int64
   * @example 60
   */
  expirationInSec: number
  /**
   * Quote amount in currency that will be credited. This currency cannot be BTC
   * @example {"currency":"USD","amount":"10.99"}
   */
  targetAmount: InvoicingCurrencyAmount
  /**
   * Quote amount in currency that will be debited. At quote creation time this will always be BTC, but that can change to other Currency later in case
   * the transaction is upgraded to direct transfer (happens if the payer is also Strike user)
   * @example {"currency":"BTC","amount":"0.00023563"}
   */
  sourceAmount: InvoicingCurrencyAmount
  /** Applied source -> target currency conversion rate */
  conversionRate: InvoicingConversionAmount
}
export enum PaymentIntentState {
  NEW = 'NEW',
  UNPAID = 'UNPAID',
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  REVERSED = 'REVERSED',
}
export enum PaymentProcessor {
  OTHER = 'OTHER',
  BLACKHAWK = 'BLACKHAWK',
}
interface PaymentQuote {
  /**
   * The payment's ID
   * @format uuid
   */
  paymentQuoteId: string
  /** The description forwarded from the related invoice */
  description?: string
  /**
   * The expiry timestamp
   * @format date-time
   */
  validUntil?: string
  /** The conversion rate details */
  conversionRate?: ConversionAmount
  /** The amount that the receiver will receive in sender’s currency */
  amount: CurrencyAmount
  /** The fee required by LN network */
  totalFee?: CurrencyAmount
  /** The amount that the sender will spend */
  totalAmount: CurrencyAmount
  /** The reward that the sender might receive, if applicable */
  reward?: CurrencyAmount
}

export interface LightningPaymentQuote extends PaymentQuote {
  lightningNetworkFee?: CurrencyAmount
}

export interface OnchainPaymentQuote extends PaymentQuote {
  estimatedDeliveryDurationInMin: number
}

export enum PaymentQuoteExecutionState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
export enum PaymentQuoteExecutionResult {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
export enum UserStates {
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  PROMO = 'PROMO',
  LIGHT = 'LIGHT',
  FULL = 'FULL',
  EMAIL_VERIFIED_INACTIVE = 'EMAIL_VERIFIED_INACTIVE',
}

export enum KycState {
  EMPTY = 'EMPTY',
  LIGHT_INPROGRESS = 'LIGHT_INPROGRESS',
  LIGHT_FORBIDDEN = 'LIGHT_FORBIDDEN',
  LIGHT_ERROR = 'LIGHT_ERROR',
  LIGHT = 'LIGHT',
  FULL_INPROGRESS = 'FULL_INPROGRESS',
  FULL_FORBIDDEN = 'FULL_FORBIDDEN',
  FULL_ERROR = 'FULL_ERROR',
  FULL = 'FULL',
}

export enum Restriction {
  NONE = 'NONE',
  DEPOSIT_ONLY = 'DEPOSIT_ONLY',
  WITHDRAWAL_ONLY = 'WITHDRAWAL_ONLY',
  DISABLED = 'DISABLED',
  CLOSED = 'CLOSED',
  READONLY = 'READONLY',
}
export interface PrivateAccountProfile {
  /**
   * Account ID
   * @format uuid
   * @example "e8717a40-0506-485c-b10c-72d2a955d873"
   */
  id: string
  /**
   * Account state
   * @example "FULL"
   */
  accountState: UserStates
  /**
   * Account created date
   * @example "2020-08-19T15:21:47.968707+02:00"
   */
  created: string
  /**
   * Restriction on the account
   * @example "NONE"
   */
  restrictionLevel: Restriction
  /**
   * Account's supported currencies
   */
  currencies: AccountCurrency[]

  fullName?: string
}

export interface SetKycCountryReq {
  country: string
  subdivision?: string
}

export interface KycDetails {
  /**
   * Account KYC state
   */
  kycState: KycState
  /**
   * Account country
   * @example "US"
   */
  country?: string
  /**
   * Is phone required for this account. Depends on the set country
   */
  isPhoneNumberRequired?: boolean
  /**
   * Is phone verified
   */
  isPhoneVerified: boolean
}
export interface PersonaInquiry {
  /**
   * Persona inquiry ID
   * @example "inq_FijCaZF4VmKLcEwCrhb8p1gb"
   */
  inquiryId: string

  /**
   * The session token for the persona inquiry
   * @example "eyJhbGciOiJIUzI1NiJ9.eyJz...0hSZXVVOW9mcyJ9.LN6DEnfjP5cZoZ3w41RgyX5QVmutmjHja_bRHOmDjg0"
   */
  sessionToken?: string
}
export type PhoneVerification =
  | { isVerified: true }
  | {
      isVerified: false
      verificationExpiry: string
      verificationId: string
    }
export interface Subscription {
  /**
   * Id of the subscription in GUID format
   * @format uuid
   */
  id: string
  /**
   * Webhook HTTPS endpoint url.
   * @example "https://kramerica_industries.com/webhook"
   */
  webhookUrl: string
  /**
   * Version that will be used when formatting the webhook payload.
   * @example "v1"
   */
  webhookVersion: ApiVersion
  /**
   * Flag for enabling/disabling the subscription. If subscription is disabled the webhook
   * won't be triggered for the respective event types.
   */
  enabled: boolean
  /**
   * Time at which subscription has been created.
   * @format date-time
   */
  created: string
  /**
   * List of event types for the subscription. Each time that some event type from the list occurs,
   * the webhook will be triggered.
   * @example ["invoice.created","invoice.updated"]
   */
  eventTypes: string[]
}
export interface UpdateSubscription {
  /**
   * Webhook HTTPS endpoint url.
   * @example "https://kramerica_industries.com/webhook"
   */
  webhookUrl?: string
  /**
   * Version that will be used when formatting the webhook payload.
   * @example "v1"
   */
  webhookVersion?: ApiVersion
  /**
   * Webhook secret that will be used for signing the request.
   * @minLength 10
   * @maxLength 50
   * @example "ScKc2Phr37RzfESKGYPh6CnZqNxc9t"
   */
  secret?: string
  /**
   * Flag for enabling/disabling the subscription. If subscription is disabled the webhook
   * won't be triggered for the respective event types.
   */
  enabled?: boolean
  /**
   * List of event types for the subscription. Each time that some event type from the list occurs,
   * the webhook will be triggered.
   * @minItems 1
   * @example ["invoice.created","invoice.updated"]
   */
  eventTypes?: string[]
}
export interface WebhookEvent {
  /**
   * The ID of the event.
   * @format uuid
   */
  id: string
  /**
   * The event that occured.
   * @example "invoice.created"
   */
  eventType: string
  /**
   * Version of the payload format.
   * @example "v1"
   */
  webhookVersion: ApiVersion
  /** The payload of the event. */
  data: any
  /**
   * Time at which the event has been created.
   * @format date-time
   */
  created: string
  /**
   * Status of the event delivery attempt.
   * If all associated delivery attempts are successful, the event is considered delivered and this is `true`.
   * Otherwise, if any delivery attempt is still pending or unsuccessful, the event is considered undelivered and this is `false`.
   * If no delivery attempt is associated with the event, this is null.
   */
  deliverySuccess?: boolean
}
export interface WebhookEventPageResult {
  /** The page items. */
  items: WebhookEvent[]
  /**
   * Total number of records.
   * @format int64
   */
  count: number
}

/** Manually added types */

export interface CreatePayoutOriginatorReq {
  type: 'INDIVIDUAL' | 'COMPANY'
  name: string
  address: {
    country: string
    state: string
    city: string
    postCode: string
    line1: string
  }
  dateOfBirth?: string
}

export interface CreatePayoutOriginatorRes {
  id: string
  state: 'PENDING_REVIEW' | 'APPROVED' | 'REJECTED' | 'INACTIVE'
  created: string
  details: {
    type: 'INDIVIDUAL' | 'COMPANY'
    name: string
    address: {
      country: string
      state: string
      city: string
      postCode: string
      line1: string
    }
    dateOfBirth?: string
  }
}

export interface CreatePayoutReq {
  paymentMethodId: string
  originatorId?: string
  amount: string
  reference?: string
}

export interface InitiatePayoutReq {
  id: string
}

export interface Payout {
  id: string
  state: 'NEW' | 'INITIATED' | 'COMPLETED' | 'FAILED' | 'REVERSED'
  created: string
  paymentMethodId: string
  originatorId?: string
  amount: CurrencyAmount
  fee: CurrencyAmount
  reference?: string
  initiated?: string
  completed?: string
}

/** Payment Methods: Bank */
export interface CreateBankPaymentMethodReq {
  transferType: BankPaymentMethodTransferType
  accountNumber: string
  routingNumber?: string
  accountType?: BankPaymentMethodAccountType
  bankName?: string
  bankAddress?: Address
  beneficiaries: BankPaymentMethodBeneficiary[]
}

export interface LinkPlaidPaymentMethodReq {
  publicToken: string
  accountId: string
}
export interface GeneratePlaidLinkReq {
  redirectUri: string
  language: 'EN' | 'ES' | 'FR' | 'NL'
  id?: string
}

export interface GeneratePlaidLinkRes {
  token: string
  validUntil: string
}

export interface BankPaymentMethod {
  id: string
  state: BankPaymentMethodState
  verificationState?: BankPaymentMethodVerificationState
  supportedActions: PaymentMethodActionType[]
  created: string
  transferType: BankPaymentMethodTransferType
  accountNumber: string
  routingNumber: string
  accountType?: BankPaymentMethodAccountType
  bankName?: string
  bankAddress?: Address
  currency: Currency
  beneficiaries: BankPaymentMethodBeneficiary[]
  referenceCode?: string
}

export enum BankPaymentMethodState {
  PENDING = 'PENDING',
  READY = 'READY',
  SUSPENDED = 'SUSPENDED',
  INVALID = 'INVALID',
  INACTIVE = 'INACTIVE',
}

export enum BankPaymentMethodVerificationState {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  USER_ACTION_REQUIRED = 'USER_ACTION_REQUIRED',
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED',
}

export enum PaymentMethodActionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum BankPaymentMethodTransferType {
  ACH = 'ACH',
  US_DOMESTIC_WIRE = 'US_DOMESTIC_WIRE',
  SEPA = 'SEPA',
  FPS = 'FPS',
}

export enum BankPaymentMethodAccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export interface Address {
  country: string
  state?: string
  city: string
  postCode: string
  line1: string
}

export type BankPaymentMethodBeneficiary =
  | {
      type: 'INDIVIDUAL'
      name: string
      address?: Address
      dateOfBirth?: string
    }
  | {
      type: 'COMPANY'
      name: string
      address?: Address
      email?: string
      phoneNumber?: string
      url?: string
    }

export interface BankPaymentMethodPageResult {
  /** The page items. */
  items: BankPaymentMethod[]
  /**
   * Total number of records.
   * @format int64
   */
  count: number
}

export interface BankPaymentMethodPageResult {
  /** The page items. */
  items: BankPaymentMethod[]
  /**
   * Total number of records.
   * @format int64
   */
  count: number
}

export interface CreateBankPaymentMethodRes {
  id: string
}

/** Payment Methods: Cards */

export enum CardPaymentMethodState {
  PENDING = 'PENDING',
  READY = 'READY',
  SUSPENDED = 'SUSPENDED',
  INVALID = 'INVALID',
  INACTIVE = 'INACTIVE',
}

export enum CardPaymentMethodVerificationState {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  USER_ACTION_REQUIRED = 'USER_ACTION_REQUIRED',
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED',
}

export interface CardPaymentMethod {
  id: string
  state: CardPaymentMethodState
  created: string
  name: string
  expiryMonth: number
  expiryYear: number
  currency: Currency
  last4: string
  scheme: string
  cardType: string
  issuerCountry: string
  verificationState: CardPaymentMethodVerificationState
}

export interface CardPaymentMethodPageResult {
  items: CardPaymentMethod[]
  count: number
}

export interface CreateCardPaymentMethodReq {
  token: string
  type: 'card'
  expiryMonth: number
  expiryYear: number
  cardholderName: string
  scheme: string
  last4: string
  bin: string
  cardType: string
  cardCategory: string
  issuer: string
  issuerCountry: string
  productId: string
  productType: string
}

export interface CreateCardPaymentMethodRes {
  id: string
}

export interface VerifyCardPaymentMethodReq {
  id: string
  verificationCode: string
}

export interface VerifyCardPaymentMethodRes {
  isSuccess: boolean
  attemptsRemaining: number
}

/** Currency Exchange Quotes */

export enum CurrencyExchangeQuoteState {
  NEW = 'NEW',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface CreateCurrencyExchangeQuote {
  sell: Currency
  buy: Currency
  amount: AmountWithFee
}

export interface CurrencyExchangeQuote {
  id: string
  created: string
  validUntil: string
  source: CurrencyAmount
  target: CurrencyAmount
  fee: CurrencyAmount
  conversionRate: ConversionAmount
  state: CurrencyExchangeQuoteState
  completed?: string
}

export interface CurrencyExchangeFee {
  source: CurrencyAmount
  target: CurrencyAmount
  fee: CurrencyAmount
  conversionRate: ConversionAmount
}

/** Reporting types */

export interface ReportPage<T> {
  items: T[]
  nextPageLink: string
}

export enum DeliveryTransactionType {
  P2P = 'P2P',
  LIGHTNING = 'LIGHTNING',
  ONCHAIN = 'ON_CHAIN',
}

export enum DeliveryTransactionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface ReceiveTransaction {
  id: string
  created: string
  completed?: string
  failed?: string
  amount: string
  currency: Currency
  feeAmount: string
  feeCurrency: Currency
  exchangeRate?: string
  type: DeliveryTransactionType
  status: DeliveryTransactionStatus
  description?: string
  invoiceId?: string
  invoiceCorrelationId?: string
}

export interface SendTransaction {
  id: string
  created: string
  completed?: string
  failed?: string
  amount: string
  currency: Currency
  feeAmount: string
  feeCurrency: Currency
  exchangeRate?: string
  type: DeliveryTransactionType
  status: DeliveryTransactionStatus
  description?: string
  destination: string
  receivedCurrency?: Currency
  exchangePair?: string
  externalRefId?: string
}

export enum BankTransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum BankTransactionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  REVERSED = 'REVERSED',
}

export interface BankTransaction {
  id: string
  type: BankTransactionType
  amount: string
  currency: Currency
  feeAmount: string
  created: string
  completed?: string
  failed?: string
  reversed?: string
  accountNumber: string
  beneficiary: string
  sender: string
  status: BankTransactionStatus
}

export enum CurrencyExchangeTransactionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface CurrencyExchangeTransaction {
  id: string
  currencySold: Currency
  amountSold: string
  currencyBought: Currency
  amountBought: string
  exchangeRate: string
  created: string
  completed?: string
  status: CurrencyExchangeTransactionStatus
}

/**
 * Generic limit value and used value
 */
export interface SpendableLimit<TLimitValue> {
  /** The limit value */
  limit: TLimitValue
  /** The amount of limit spent so far */
  used: TLimitValue
}

/**
 * Limit values for a certain period of days
 */
export interface CycleLimit<TLimitValue> extends SpendableLimit<TLimitValue> {
  /** The number of days in the limit period */
  days: number
}

/**
 * Limits on transaction amounts.
 * Object with only 'effective' property is invalid. At least one of the optional properties must be present too
 */
export interface AmountLimits {
  /** Currently effective limit value */
  effective: CurrencyAmount
  /** Single transaction limit value */
  single?: CurrencyAmount
  /** Lifetime limit value */
  lifetime?: SpendableLimit<CurrencyAmount>
  /** Calendar year limit value */
  calendarYear?: SpendableLimit<CurrencyAmount>
  /** List of limit values for different time periods */
  cycles?: CycleLimit<CurrencyAmount>[]
}

/**
 * Limits on the number of transactions
 * Object with only 'effective' property is invalid. At least one of the optional properties must be present too
 */
export interface CountLimits {
  /** Currently effective limit value */
  effective: number
  /** Lifetime limit value */
  lifetime?: SpendableLimit<number>
  /** Yearly limit value */
  calendarYear?: SpendableLimit<number>
  /** List of limit values for different time periods */
  cycles?: CycleLimit<number>[]
}

/**
 * Set of limits on transaction counts and amounts
 */
export interface LimitsSet {
  /** Limits on the number of transactions */
  count?: CountLimits
  /** Limits on the total amount of transactions */
  amount?: AmountLimits
}

/**
 * Deposit limits and Send limits
 */
export interface AccountLimits {
  /** Deposit limits. Applicable for all deposits other than ACH and card deposits, or if the ACH or card deposit limits are not defined */
  deposit?: LimitsSet
  /** Ach deposit limits. Applicable for ACH deposits only. If these are not defined, `deposit` limits apply */
  achDeposit?: LimitsSet
  /** Card deposit limits. Applicable for card deposits only. If these are not defined, `deposit` limits apply */
  cardDeposit?: LimitsSet
  /** Send limits. Applicable for all outgoing transactions except for bank transfers */
  send?: LimitsSet
  /** Withdrawal limits. Applicable for all bank transfers */
  withdrawal?: LimitsSet
  /** Instant limits. If not 0, allows spending the pending balance up to the defined limit */
  instant?: LimitsSet
}

export interface ThreeDsChallengeSettings {
  /**
   * URL to redirect to when the 3ds challenge is successful
   */
  successUrl: string
  /**
   * URL to redirect to when the 3ds challenge has failed
   */
  failureUrl: string
}

export interface InitiateDepositRequest {
  /**
   * ID of the payment method to deposit from
   */
  paymentMethodId: string
  /**
   * Amount to deposit.
   * Currency is defined by the payment method
   */
  amount: string
  /**
   * Should the deposit fee be included in the amount or added on top of it.
   * Defaults to EXCLUSIVE.
   */
  feePolicy?: FeePolicy
  /**
   * The 3ds challenge settings. Needs to be provided if the payment method requires 3ds.
   */
  threeDsChallengeSettings?: ThreeDsChallengeSettings
}

export interface GetDepositFeeRequest {
  /**
   * ID of the payment method to estimate deposit from
   */
  paymentMethodId: string
  /**
   * Amount to deposit.
   * Currency is defined by the payment method
   */
  amount: string
  /**
   * Should the deposit fee be included in the amount or added on top of it.
   * Defaults to EXCLUSIVE.
   */
  feePolicy?: FeePolicy
}

export enum DepositState {
  /**
   * The deposit is not yet being processed and 3DS verification is not yet ready
   */
  NEW = 'NEW',
  /**
   * The deposit or 3DS verification is in process
   */
  PENDING = 'PENDING',
  /**
   * The deposit has been successfully processed
   */
  COMPLETED = 'COMPLETED',
  /**
   * The deposit has failed processing
   */
  FAILED = 'FAILED',
  /**
   * The deposit has been reversed
   */
  REVERSED = 'REVERSED',
}

export enum DepositFailureReason {
  INVALID_PAYMENT_METHOD_CREDENTIALS = 'INVALID_PAYMENT_METHOD_CREDENTIALS',
  RESTRICTED_CARD = 'RESTRICTED_CARD',
  BANK_INSUFFICIENT_FUNDS = 'BANK_INSUFFICIENT_FUNDS',
  DO_NOT_HONOR = 'DO_NOT_HONOR',
  THREE_DS_NOT_VERIFIED = 'THREE_DS_NOT_VERIFIED',
  THREE_DS_NOT_ACCEPTED = 'THREE_DS_NOT_ACCEPTED',
  AMOUNT_TOO_HIGH = 'AMOUNT_TOO_HIGH',
  CARD_NOT_ALLOWED = 'CARD_NOT_ALLOWED',
  INSTITUTION_NOT_RESPONDING = 'INSTITUTION_NOT_RESPONDING',
  INSTITUTION_NOT_SUPPORTED = 'INSTITUTION_NOT_SUPPORTED',
  INVALID_ACCOUNT_ID = 'INVALID_ACCOUNT_ID',
  UNABLE_TO_AUTHORIZE_CARD = 'UNABLE_TO_AUTHORIZE_CARD',
  CARD_NOT_VERIFIED = 'CARD_NOT_VERIFIED',
  INVALID_BILLING_ADDRESS = 'INVALID_BILLING_ADDRESS',
  INVALID_CREDIT_CARD_RESOURCE = 'INVALID_CREDIT_CARD_RESOURCE',
  UNABLE_TO_TOKENIZE_CARD = 'UNABLE_TO_TOKENIZE_CARD',
  THREE_DS_NETWORK_ERROR = 'THREE_DS_NETWORK_ERROR',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  CARD_EXPIRED = 'CARD_EXPIRED',
  SUSPECTED_FRAUD = 'SUSPECTED_FRAUD',
  CARD_CVV_FAILURE = 'CARD_CVV_FAILURE',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  PICK_UP_CARD = 'PICK_UP_CARD',
  NOT_ALLOWED_BY_CARD_ISSUER = 'NOT_ALLOWED_BY_CARD_ISSUER',
  RISK_BLOCKED = 'RISK_BLOCKED',
  RISK_CARD_NUMBER_BLACKLIST = 'RISK_CARD_NUMBER_BLACKLIST',
  RISK_IP_BLACKLIST = 'RISK_IP_BLACKLIST',
  RISK_EMAIL_BLACKLIST = 'RISK_EMAIL_BLACKLIST',
  RISK_PHONE_BLACKLIST = 'RISK_PHONE_BLACKLIST',
  RISK_BIN_BLACKLIST = 'RISK_BIN_BLACKLIST',
  INITIATOR_CANCELLED = 'INITIATOR_CANCELLED',
  VOIDED = 'VOIDED',
  TRANSACTION_EXPIRED = 'TRANSACTION_EXPIRED',
  TRANSACTION_DECLINED = 'TRANSACTION_DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  PARTIAL_VALUE_APPROVED = 'PARTIAL_VALUE_APPROVED',
  INVALID_TRANSACTION = 'INVALID_TRANSACTION',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  INITIATOR_DISPUTE = 'INITIATOR_DISPUTE',
  INVALID_RESPONSE = 'INVALID_RESPONSE',
  NO_ACTION_TAKEN = 'NO_ACTION_TAKEN',
  SUSPECTED_MALFUNCTION = 'SUSPECTED_MALFUNCTION',
  UNACCEPTABLE_TRANSACTION_FEE = 'UNACCEPTABLE_TRANSACTION_FEE',
  FILE_UPDATE_ERROR = 'FILE_UPDATE_ERROR',
  COMPLETED_PARTIALLY = 'COMPLETED_PARTIALLY',
  PIN_ENTRY_EXCEEDED = 'PIN_ENTRY_EXCEEDED',
  NO_CREDIT_ACCOUNT = 'NO_CREDIT_ACCOUNT',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NO_UNIVERSAL_AMOUNT = 'NO_UNIVERSAL_AMOUNT',
  ACCOUNT_MISSING = 'ACCOUNT_MISSING',
  CARD_ACCEPTOR_CONTACT_AQUIRER = 'CARD_ACCEPTOR_CONTACT_AQUIRER',
  AMOUNT_LIMIT_EXCEEDED = 'AMOUNT_LIMIT_EXCEEDED',
  SECURITY_VIOLATION = 'SECURITY_VIOLATION',
  AML_REJECTION = 'AML_REJECTION',
  HARD_CAPTURE = 'HARD_CAPTURE',
  BLOCKED_FIRST_USE = 'BLOCKED_FIRST_USE',
  PBF_ERROR = 'PBF_ERROR',
  UNABLE_TO_DISPENSE = 'UNABLE_TO_DISPENSE',
  CUT_OFF_IN_PROGRESS = 'CUT_OFF_IN_PROGRESS',
  ADMINISTRATION_ERROR = 'ADMINISTRATION_ERROR',
  DESTINATION_NOT_FOUND = 'DESTINATION_NOT_FOUND',
  LAW_VIOLATION = 'LAW_VIOLATION',
  DUPLICATE_TRANSMISSION = 'DUPLICATE_TRANSMISSION',
  RECONCILE_ERROR = 'RECONCILE_ERROR',
  SYSTEM_MALFUNCTION = 'SYSTEM_MALFUNCTION',
  MAC_ERROR = 'MAC_ERROR',
  UNITENTIFIED_RESPONSE = 'UNITENTIFIED_RESPONSE',
  FORCE_STIP = 'FORCE_STIP',
  PIN_REQUIRED = 'PIN_REQUIRED',
  PTLF_FULL = 'PTLF_FULL',
  INVALID_TRANSACTION_DATE = 'INVALID_TRANSACTION_DATE',
  CAF_STATUS = 'CAF_STATUS',
  INVALID_MERCHANT = 'INVALID_MERCHANT',
  GATEWAY_REJECT = 'GATEWAY_REJECT',
  DECLINED_UPDATED_CARDHOLDER = 'DECLINED_UPDATED_CARDHOLDER',
  TRANSACTION_ALREADY_REVERSED = 'TRANSACTION_ALREADY_REVERSED',
  AUTHORIZATION_COMPLETED = 'AUTHORIZATION_COMPLETED',
  SECURE_CODE_NOT_ENABLED = 'SECURE_CODE_NOT_ENABLED',
  TRANSACTION_PENDING = 'TRANSACTION_PENDING',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
  REFUND_PERIOD_EXPIRED = 'REFUND_PERIOD_EXPIRED',
  POLICY_ERROR = 'POLICY_ERROR',
  SUSPECTED_FRAUD_SECURITY = 'SUSPECTED_FRAUD_SECURITY',
  CARD_LOST = 'CARD_LOST',
  CARD_STOLEN = 'CARD_STOLEN',
  AMLD5_REJECTION = 'AMLD5_REJECTION',
  INVALID_TRANSFER_TYPE = 'INVALID_TRANSFER_TYPE',
  CLOSED_ACCOUNT = 'CLOSED_ACCOUNT',
  UNKNOWN = 'UNKNOWN',
}

export interface Deposit {
  /**
   * ID of the deposit.
   */
  id: string
  /**
   * The URL to redirect the user to in case the 3DS verification is needed.
   */
  verificationRedirectUrl?: string
  /**
   * The state of the deposit process. The deposit can be created in either `New` or `Pending` state.
   */
  state: DepositState
  /**
   * The reason for the deposit failure, if deposit fails
   */
  failureReason?: DepositFailureReason
  /**
   * The amount to be credited to the Strike account.
   */
  amount: CurrencyAmount
  /**
   * The amount to be charged as the fee for the deposit.
   */
  fee: CurrencyAmount
  /**
   * The total amount to be deposited from the payment method.
   */
  totalAmount: CurrencyAmount
}

export interface DepositFee {
  /**
   * Transaction fee to be paid.
   */
  fee: CurrencyAmount
  /**
   * Total amount to be charged on the payment method.
   */
  total: CurrencyAmount
  /**
   * Amount to be deposited
   */
  amount: CurrencyAmount
  /**
   * The fee percentage, if applicable
   */
  feePercent?: string
  /**
   * The fixed fee, if applicable
   */
  feeFixed?: string
  settlementPeriodInDay?: number
  settledAt?: string
}

export type RatesHistoryPeriod = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'ALL'

export type RatePoint = 'OPEN' | 'HIGH' | 'LOW' | 'CLOSE'

export type RatesHistoryItem = { time: string } & Partial<Record<Lowercase<RatePoint>, string>>

export type RatesHistory = {
  sell: Currency
  buy: Currency
  period: RatesHistoryPeriod
  items: RatesHistoryItem[]
}

export type CreateBolt11ReceiveRequest = {
  /**
   * Amount to be used for the lightning invoice. If not set, zero amount invoice will be created.
   * If currency is not BTC, the amount will be converted to BTC using the current exchange rate.
   */
  amount?: CurrencyAmount | null
  /**
   * Lightning invoice description.
   */
  description?: string | null
  /**
   * Lightning invoice description hash hex string. If provided, the invoice description will be ignored in favor of this hash
   */
  descriptionHash?: string | null
  /**
   * Lightning invoice expiry in seconds
   */
  expiryInSeconds?: number | null
}

export type CreateBolt12ReceiveRequest = Record<string, any>

export type CreateOnchainReceiveRequest = {
  /**
   * Amount to be suggested to the payer.
   */
  amount?: CurrencyAmount | null
}

export type CreateReceiveRequest = {
  /**
   * Bolt11 receive params.
   * Send as an empty object to use a default configuration. If the object is not set at all, bolt11 invoice will not be created for the receive request.
   */
  bolt11?: CreateBolt11ReceiveRequest | null
  /**
   * Bolt12 receive params.
   * Send as an empty object to use a default configuration. If the object is not set at all, bolt12 offer will not be created for the receive request.
   */
  bolt12?: CreateBolt12ReceiveRequest | null
  /**
   * Onchain receive params.
   * Send as an empty object to use a default configuration. If the object is not set at all, onchain address will not be created for the receive request.
   */
  onchain?: CreateOnchainReceiveRequest | null
  /**
   * Currency to convert the received amounts to.
   * Each receive for this request will be converted to the target currency using the current exchange rate. The amount credited can be different from the
   * requested amount due to exchange rate fluctuations.
   */
  targetCurrency?: Currency | null
}

export type Bolt11ReceiveRequest = {
  /**
   * BOLT 11 lightning invoice.
   */
  invoice: string
  /**
   * Requested invoice amount.
   */
  requestedAmount?: CurrencyAmount | null
  /**
   * Invoice amount in BTC.
   * This is a requested amount converted to BTC based on the current exchange rate. If the requested amount is already in BTC the values will be the same.
   */
  btcAmount?: string | null
  /**
   * Invoice description.
   */
  description?: string | null
  /**
   * Invoice description hash hex string.
   */
  descriptionHash?: string | null
  /**
   * Invoice payment hash hex string.
   */
  paymentHash: string
  /**
   * Time of invoice expiration.
   */
  expires: string
}

export type Bolt12ReceiveRequest = {
  offer: string
}

export type OnchainReceiveRequest = {
  /**
   * Onchain address to be paid to.
   */
  address: string
  /**
   * Onchain address URI to be paid to.
   */
  addressUri: string
  /**
   * Requested amount.
   */
  requestedAmount?: CurrencyAmount | null
  /**
   * Amount in BTC suggested to be paid.
   * This is a requested amount converted to BTC based on the current exchange rate. If the requested amount is already in BTC the values will be the same.
   */
  btcAmount?: string | null
}

export type ReceiveRequest = {
  /**
   * ID of the receive request.
   */
  receiveRequestId: string
  /**
   * Time of receive request creation.
   */
  created: string
  /**
   * Currency to which each receive for this request will be converted.
   * Conversion will be done using the current exchange rate at the time of the receive. The amount credited can be different from the requested amount due
   * to exchange rate fluctuations.
   */
  targetCurrency?: Currency | null
  /**
   * Bolt11 receive request.
   */
  bolt11?: Bolt11ReceiveRequest | null
  /**
   * Bolt12 receive request.
   */
  bolt12?: Bolt12ReceiveRequest | null
  /**
   * Onchain receive request.
   */
  onchain?: OnchainReceiveRequest | null
}

export type MultiFactorStatus = {
  enabled: boolean
}

export type MultiFactorVerificationRequest = {
  passCode: string
}

export type MultiFactorVerificationResult = {
  valid: boolean
}

export type MultiFactorSetup = {
  secretKey: string
  qrCodeUri: string
}

export type TotpEnabled = {
  enabled: boolean
  recoveryCodes?: string[]
}

export const downloadFile = (data: Blob, filename: string) => {
  // hacky way to download file from API
  const blobUrl = window.URL.createObjectURL(data)
  const blobLink = document.createElement('a')

  blobLink.href = blobUrl
  blobLink.download = filename

  // we need to append the element to the dom -> otherwise it will not work in firefox
  document.body.appendChild(blobLink)

  blobLink.click()

  // cleanup
  blobLink.remove()
}
